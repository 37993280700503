<template>
	<custom-page>
		<group-card title="custom.page4.接管参数">
			<!--材质-->
			<group-item label="custom.page4.材质">
				<el-radio-group v-model="form.material" @change="handleMaterialChange">
					<el-radio label="铜">{{ $t("custom.material.铜") }}</el-radio>
					<el-radio label="铝">{{ $t("custom.material.铝") }}</el-radio>
				</el-radio-group>
			</group-item>

			<!--形状-->
			<group-item label="custom.page4.形状">
				<el-radio-group v-model="form.shape" @change="handleShapeChange">
					<el-radio :label="0">{{ $t("custom.shap.0") }}</el-radio>
					<el-radio :label="1">{{ $t("custom.shap.1") }}</el-radio>
				</el-radio-group>
			</group-item>

			<!--位置-->
			<group-item label="custom.page4.位置">
				<el-radio-group v-model="form.location">
					<div v-for="(l,i) in source.location" :key="i" class="radio-item">
						<el-radio :label="l" class="radio">
							<img :src="$req.getFileUrl(l.img)" alt="" class="image" />
						</el-radio>
					</div>
				</el-radio-group>
			</group-item>

			<!--进口管-->
			<group-item label="custom.page4.进口管">
				<group-item-column>
					<el-button size="small" @click="choose('in')">{{ $t("common.actions.choose") }}</el-button>
				</group-item-column>
				<group-item-column v-if="form.in">
					<div class="select-model">
						<div class="item">
							<div>{{ $t("custom.page4.管径") }}</div>
							<div>{{ form.in.otherObj.diameter.value }}</div>
						</div>
						<div class="item">
							<div>{{ $t("custom.page4.壁厚") }}</div>
							<div>{{ form.in.otherObj.thickness.value }}</div>
						</div>
						<div class="item">
							<div>{{ $t("custom.page4.是否扩口") }}</div>

							<div>
								{{ form.in.otherObj.Flaring.value ?  $t('common.boolean.true'): $t('common.boolean.false')  }}
							</div>
							<!-- <div>{{ form.in.otherObj.flaring.value ? $t("common.null") : form.in.otherObj.flaringID.value }}</div> -->
						</div>
					</div>
				</group-item-column>
			</group-item>

			<!--出口管-->
			<group-item label="custom.page4.出口管">
				<group-item-column>
					<el-button size="small" @click="choose('out')">{{ $t("common.actions.choose") }}</el-button>
				</group-item-column>
				<group-item-column v-if="form.out">
					<div class="select-model">
						<div class="item">
							<div>{{ $t("custom.page4.管径") }}</div>
							<div>{{ form.out.otherObj.diameter.value }}</div>
						</div>
						<div class="item">
							<div>{{ $t("custom.page4.壁厚") }}</div>
							<div>{{ form.out.otherObj.thickness.value }}</div>
						</div>

						<div class="item">
							<div>{{ $t("custom.page4.是否扩口") }}</div>
							<div>
								{{ form.out.otherObj.Flaring.value?  $t('common.boolean.true'): $t('common.boolean.false')  }}
							</div>
						</div>
					</div>
				</group-item-column>

			</group-item>

		</group-card>

		<div class="action-bar">
			<el-button type="" class="btn" @click="handlePrevClick">{{ $t("common.actions.pre") }}</el-button>
			<el-button type="primary" class="btn" @click="handleNextClick" :disabled="!(form.in && form.out)">
				{{ $t("common.actions.next") }}
			</el-button>
		</div>


		<el-dialog :visible.sync="showDialog">
			<table-selct :list="source.tubeList" :column-option="tubeOpt" @select="handleSelect" ref="tableSelect" />
			<div style="text-align: right;margin-top: 30px">
				<el-button type="primary" @click="handleSelectConfirm" size="small"> {{ $t("common.actions.confirm") }}
				</el-button>
			</div>
		</el-dialog>
	</custom-page>
</template>

<script>
	import CustomPage from "@/views/Custom/components/CustomPage";
	import GroupCard from "@/views/Custom/components/GroupCard";
	import GroupItem from "@/views/Custom/components/GroupItem";
	import mixin from "@/views/Custom/components/mixins/mixins";
	import {
		mapGetters
	} from "vuex";
	import GroupItemColumn from "@/views/Custom/components/GroupItemColumn";
	import TableSelct from "@/views/Custom/components/TableSelct";
	import {
		deepClone,
		generatePartOtherObj
	} from "@/utils";

	export default {
		name: "page4",
		components: {
			TableSelct,
			GroupItemColumn,
			GroupItem,
			GroupCard,
			CustomPage
		},
		data() {
			return {
				form: {
					material: '铜',
					shape: 0,
					location: null,
					in: null,
					out: null,
				},
				source: {
					location: [],
					tubeList: [],
				},
				showDialog: false,
				selectTube: null,
				chooseType: 'in',
				tubeOpt: [{
						title: 'custom.page4.管径',
						prop: 'diameter'
					},
					{
						title: 'custom.page4.壁厚',
						prop: 'thickness'
					},
					// {
					// 	title: 'custom.page4.是否扩口',
					// 	prop: 'flaring'
						
					// },
					{
						title: 'custom.page4.是否扩口',
							prop: 'flaring'
					},
					{
						title: 'custom.page4.扩口大小',
						prop: 'flaringID'
					},
				],
			}
		},
		mixins: [mixin],

		computed: {
			...mapGetters(["customInfo"]),
		},
		mounted() {
			this.getPositionList();
			this.getTubeList();
		},
		methods: {
			sFormat(row){
				return 0;
			},
			handleMaterialChange(e) {
				this.form.material = e;
				this.getTubeList();


			},
			handleShapeChange(val) {
				this.form.location = null;
				this.getPositionList();
			},
			choose(type) {
				this.chooseType = type;
				this.showDialog = true;
			},
			handleSelect(model) {
				this.selectTube = model
			},
			handleSelectConfirm() {
				if (this.selectTube) {
					this.$set(this.form, this.chooseType, deepClone(this.selectTube))
					this.showDialog = false;
					this.$refs.tableSelect.clearChoose()
					this.selectTube = null
				}
			},


			getPositionList() {
				let module = this.module;
				let supplierId = this.customInfo.supplierId;
				let isCov = this.customInfo.page3.type == 0
				let isL = this.form.shape == 1;
				let parma = `${isCov ? 'Cov' : 'Motn'}-${isL ? 'L' : 'I'}`

				let params = {
					parma,
					module,
					supplierId
				}

				this.$req.get("/client/goods/part/matchButtTubePosition", params).then(res => {
					res.forEach(generatePartOtherObj)
					this.$set(this.source, "location", res)
					if (res.length > 0) {
						this.$set(this.form, "location", res[0])
					}
				})
			},
			getTubeList() {
				let otherObj = this.customInfo.collectorTube.otherObj;
				let supplierId = this.customInfo.supplierId;
				let params = {
					module: this.module,
					supplierId,

					manifoldOD: otherObj.manifoldOD.value,
					thickness: otherObj.thickness.value,
					material: this.form.material
				}
				this.$req.get("/client/goods/part/matchButtTube", params).then(res => {
					res.forEach(generatePartOtherObj)
					this.$set(this.source, "tubeList", res)
				})
			},

			beforeNext() {

				this.$store.commit("SET_CUSTOM_INFO", {
					buttTube: this.form
				})
				return true;
			},
		},
	}
</script>

<style scoped lang='scss'>
	.action-bar {
		text-align: center;

		.btn {
			width: 330px;
		}
	}

	.radio-item {
		display: flex;
		align-items: center;

		.radio {
			display: flex;
			align-items: center;

			.image {
				height: 200px;
				width: 200px;
				object-fit: contain;
			}
		}
	}

	.select-model {
		display: flex;

		.item {
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			align-items: center;
			font-size: 14px;
			min-width: 100px;

			&+.item {
				margin-left: 30px;
			}
		}
	}
</style>
